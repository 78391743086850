.logo a {
    color: #fff;
    font-weight: 900;
    opacity: 0.9;
    size: 24px;
}

.logo a:hover {
    color: #fff;
    opacity: 1;
}

.logo a span {
    font-weight: 100;
}