.App {
  line-height: 16px;
  padding: 20px;
  text-align: left;
}

.quote {
  padding: 20px 0;
}

.calendly-inline-widget {
  min-height:1060px;
  min-width:320px;
}

.mission-statement {
  font-size: 28px;
  line-height: normal;
}

.contact-us__button {
  margin: 20px 0;
  border-radius: 2px;
  /* color: #011627; */
  color: #011627;
  font-weight: 300;
  padding: 12px;
  /*background-color: #03c04a;*/
  background-color: #03c04a;
}

.modal__close-button {
  font-size: 24px;
  color: #03c04a;
  background-color: transparent;
  font-weight: 100;
  border: 1px solid #03c04a;
  padding: 5px 10px;
}

.modal {
  /*...*/
  visibility: hidden;
  transition: visibility 0s 0.6s;
  margin-left: 20px;
}

.modal.modal--is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.modal__header {
  /*...*/
  position: fixed;
  top: 0;
  width: 90%;
  height: 50px;
  transition: transform 0.3s 0s;
  transform: translateY(-50px);
  z-index: 100;
  padding: 10px 20px;
}

.modal--from-right .modal__header {
  right: 0;
}

.modal--from-left .modal__header {
  left: 0;
}

.modal--is-visible .modal__header {
  transition: transform 0.3s 0.3s;
  transform: translateY(0px);
}

.modal__container {
  /*
  border-left: 1px solid #1c3f53;
  background-color: #011627;
   */
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  background-color: #011627;
  padding: 20px 20px;
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  transition: transform 0.3s 0.3s;
}

@media only screen and (max-width: 767px) {
  .modal__container {
    padding: 70px 20px;
  }
}

.modal--from-right .modal__container {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.modal--from-left .modal__container {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.modal--is-visible .modal__container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

p {
  margin-bottom: 10px;
}

header {
  padding-bottom: 20px;
}

footer {
  padding-top: 20px;
}

.birdseed {
  font-size: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
