/* Reset.less
 * Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an adapted version here	that cuts out some of the reset HTML elements we will never need here (i.e., dfn, samp, etc).
 * ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
 html,
 body {
   margin: 0;
   padding: 0;
 }
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 blockquote,
 pre,
 a,
 abbr,
 acronym,
 address,
 cite,
 code,
 del,
 dfn,
 em,
 img,
 q,
 s,
 samp,
 small,
 strike,
 strong,
 sub,
 sup,
 tt,
 var,
 dd,
 dl,
 dt,
 li,
 ol,
 ul,
 fieldset,
 form,
 label,
 legend,
 button,
 table,
 caption,
 tbody,
 tfoot,
 thead,
 tr,
 th,
 td {
   margin: 0;
   padding: 0;
   border: 0;
   font-weight: 100;
   font-style: normal;
   font-size: 100%;
   line-height: 1;
   font-family: 'Lato', sans-serif, inherit;
 }

 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 ol,
 ul {
   list-style: none;
 }
 q:before,
 q:after,
 blockquote:before,
 blockquote:after {
   content: "";
 }
 html {
   font-size: 100%;
   -webkit-text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
 }
 a:focus {
   outline: thin dotted;
 }
 a:hover,
 a:active {
   outline: 0;
 }
 article,
 aside,
 details,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 nav,
 section {
   display: block;
 }
 audio,
 canvas,
 video {
   display: inline-block;
   *display: inline;
   *zoom: 1;
 }
 audio:not([controls]) {
   display: none;
 }
 sub,
 sup {
   font-size: 75%;
   line-height: 0;
   position: relative;
   vertical-align: baseline;
 }
 sup {
   top: -0.5em;
 }
 sub {
   bottom: -0.25em;
 }
 img {
   border: 0;
   -ms-interpolation-mode: bicubic;
 }
 button,
 input,
 select,
 textarea {
   font-size: 100%;
   margin: 0;
   vertical-align: baseline;
   *vertical-align: middle;
 }
 button,
 input {
   line-height: normal;
   *overflow: visible;
 }
 button::-moz-focus-inner,
 input::-moz-focus-inner {
   border: 0;
   padding: 0;
 }
 button,
 input[type="button"],
 input[type="reset"],
 input[type="submit"] {
   cursor: pointer;
   -webkit-appearance: button;
 }
 input[type="search"] {
   -webkit-appearance: textfield;
   box-sizing: content-box;
 }
 input[type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
 }
 textarea {
   overflow: auto;
   vertical-align: top;
 }
 @keyframes spin {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(359deg);
   }
 }
 @keyframes glow {
   0%,
   100% {
     opacity: 1;
   }
   50% {
     opacity: 0.5;
   }
 }
 @font-face {
   font-family: 'Nunito Sans';
   font-style: italic;
   font-weight: 400;
   src: url(https://fonts.gstatic.com/s/nunitosans/v6/pe0oMImSLYBIv1o4X1M8cce4E9lKcw.ttf) format('truetype');
 }
 @font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 400;
   src: url(https://fonts.gstatic.com/s/nunitosans/v6/pe0qMImSLYBIv1o4X1M8cce9I94.ttf) format('truetype');
 }
 @font-face {
   font-family: 'Nunito Sans';
   font-style: normal;
   font-weight: 800;
   src: url(https://fonts.gstatic.com/s/nunitosans/v6/pe03MImSLYBIv1o4X1M8cc8aBc5tU1Q.ttf) format('truetype');
 }
 .force-wrap {
   word-wrap: break-word;
   word-break: break-all;
   -ms-word-break: break-all;
   word-break: break-word;
   -webkit-hyphens: auto;
       -ms-hyphens: auto;
           hyphens: auto;
 }
 .type-light {
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 300;
 }
 .type-bold {
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 800;
 }
 .type-italic {
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 400;
   font-style: italic;
 }
 * {
   box-sizing: border-box;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-tap-highlight-color: transparent;
 }
 html,
 button,
 input,
 textarea,
 select {
   outline: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 body {
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-style: normal;
   color: #fff;
   font-size: 16px;
   background-color: #011627;
   /*background-color: #011627;*/
 }
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-style: 400;
   margin: 0;
   padding: 0;
 }
 h1 {
   font-size: 40px;
   line-height: 48px;
 }
 h2 {
   font-size: 28px;
   line-height: 32px;
 }
 h3 {
   font-size: 24px;
   line-height: 28px;
 }
 h4 {
   font-size: 20px;
   line-height: 24px;
 }
 h5 {
   font-size: 14px;
   line-height: 20px;
   color: #ccc;
   text-transform: uppercase;
 }
 h6 {
   color: #aaa;
 }
 p {
   font-size: 16px;
   line-height: 24px;
 }
 sub,
 sup {
   font-size: 0.8em;
 }
 sub {
   bottom: -0.2em;
 }
 sup {
   top: -0.2em;
 }
 b {
   font-weight: bold;
 }
 em {
   font-style: italic;
 }
 input[type="text"],
 input[type="email"],
 input[type="password"],
 textarea {
   font-size: 14px;
   line-height: 20px;
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-style: 400;
   padding: 0.75rem 0;
   line-height: 1.5rem !important;
   border: none;
   border-radius: 0;
   box-sizing: border-box;
   color: #fff;
   outline: none;
 }
 input[type="text"] ::-webkit-input-placeholder, input[type="email"] ::-webkit-input-placeholder, input[type="password"] ::-webkit-input-placeholder, textarea ::-webkit-input-placeholder {
   color: #778b91;
 }
 input[type="text"] :-ms-input-placeholder, input[type="email"] :-ms-input-placeholder, input[type="password"] :-ms-input-placeholder, textarea :-ms-input-placeholder {
   color: #778b91;
 }
 input[type="text"] ::-ms-input-placeholder, input[type="email"] ::-ms-input-placeholder, input[type="password"] ::-ms-input-placeholder, textarea ::-ms-input-placeholder {
   color: #778b91;
 }
 input[type="text"] ::placeholder,
 input[type="email"] ::placeholder,
 input[type="password"] ::placeholder,
 textarea ::placeholder {
   color: #778b91;
 }
 input[type="text"][disabled],
 input[type="email"][disabled],
 input[type="password"][disabled],
 textarea[disabled] {
   opacity: 0.5;
 }
 input:-webkit-autofill {
   -webkit-box-shadow: 0 0 0 1000px white inset;
 }
 .checkbox {
   display: inline-block;
   height: 3rem;
   position: relative;
   vertical-align: middle;
   width: 44px;
 }
 .checkbox input[type="checkbox"] {
   font-size: 1em;
   visibility: hidden;
 }
 .checkbox input[type="checkbox"] + span:before {
   position: absolute;
   top: 50%;
   right: auto;
   bottom: auto;
   left: 50%;
   width: 0.85em;
   height: 0.85em;
   transform: translate3d(-50%, -50%, 0);
   background: transparent;
   box-shadow: #2cc5d2 0 0 0 1px inset;
   content: '';
   display: block;
 }
 .checkbox input[type="checkbox"]:checked + span:before {
   font-size: 16px;
   line-height: 24px;
   box-shadow: none;
   color: #2cc5d2;
   margin-top: -1px;
   font-family: 'percolate';
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-feature-settings: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   content: "\e65e";
 }
 .input-symbol {
   display: inline-block;
   position: relative;
 }
 .input-symbol.error [class^="icon-"],
 .input-symbol.error [class*=" icon-"] {
   color: #ff4400;
 }
 .input-symbol [class^="icon-"],
 .input-symbol [class*=" icon-"] {
   left: 1em;
 }
 .input-symbol input {
   padding-left: 3em;
 }
 .input-symbol input {
   width: 100%;
 }
 .input-symbol input:focus + [class^="icon-"],
 .input-symbol input:focus + [class*=" icon-"] {
   color: #2cc5d2;
 }
 .input-symbol [class^="icon-"],
 .input-symbol [class*=" icon-"] {
   transition: all 300ms ease-in;
   transform: translate3d(0, -50%, 0);
   background: transparent;
   color: #aaa;
   font-size: 1em;
   height: 1em;
   position: absolute;
   top: 50%;
   width: 1em;
 }
 @font-face {
   font-family: "percolate";
   src: url(/static/media/percolate.944f04e6.eot);
   src: url(/static/media/percolate.944f04e6.eot?#iefix5w3um4) format("embedded-opentype"),
     url(/static/media/percolate.19e89b87.woff) format("woff"),
     url(/static/media/percolate.fae78e19.ttf) format("truetype"),
     url(/static/media/percolate.1527b5e7.svg) format("svg");
   font-weight: normal;
   font-style: normal;
 }
 [class^="icon-"],
 [class*=" icon-"] {
   font-family: "percolate";
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-feature-settings: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 .icon-graphql:before {
   content: "\e90a";
 }
 .icon-redux:before {
   content: "\e908";
 }
 .icon-grid:before {
   content: "\e909";
 }
 .icon-redirect:before {
   content: "\e907";
 }
 .icon-grow:before {
   content: "\e903";
 }
 .icon-lightning:before {
   content: "\e904";
 }
 .icon-request-change:before {
   content: "\e905";
 }
 .icon-transfer:before {
   content: "\e906";
 }
 .icon-calendar:before {
   content: "\e902";
 }
 .icon-sidebar:before {
   content: "\e900";
 }
 .icon-tablet:before {
   content: "\e901";
 }
 .icon-atmosphere:before {
   content: "\e671";
 }
 .icon-browser:before {
   content: "\e672";
 }
 .icon-database:before {
   content: "\e673";
 }
 .icon-expand-alt:before {
   content: "\e674";
 }
 .icon-mobile:before {
   content: "\e675";
 }
 .icon-watch:before {
   content: "\e676";
 }
 .icon-home:before {
   content: "\e600";
 }
 .icon-user-alt:before {
   content: "\e601";
 }
 .icon-user:before {
   content: "\e602";
 }
 .icon-user-add:before {
   content: "\e603";
 }
 .icon-users:before {
   content: "\e604";
 }
 .icon-profile:before {
   content: "\e605";
 }
 .icon-bookmark:before {
   content: "\e606";
 }
 .icon-bookmark-hollow:before {
   content: "\e607";
 }
 .icon-star:before {
   content: "\e608";
 }
 .icon-star-hollow:before {
   content: "\e609";
 }
 .icon-circle:before {
   content: "\e60a";
 }
 .icon-circle-hollow:before {
   content: "\e60b";
 }
 .icon-heart:before {
   content: "\e60c";
 }
 .icon-heart-hollow:before {
   content: "\e60d";
 }
 .icon-face-happy:before {
   content: "\e60e";
 }
 .icon-face-sad:before {
   content: "\e60f";
 }
 .icon-face-neutral:before {
   content: "\e610";
 }
 .icon-lock:before {
   content: "\e611";
 }
 .icon-unlock:before {
   content: "\e612";
 }
 .icon-key:before {
   content: "\e613";
 }
 .icon-arrow-left-alt:before {
   content: "\e614";
 }
 .icon-arrow-right-alt:before {
   content: "\e615";
 }
 .icon-sync:before {
   content: "\e616";
 }
 .icon-reply:before {
   content: "\e617";
 }
 .icon-expand:before {
   content: "\e618";
 }
 .icon-arrow-left:before {
   content: "\e619";
 }
 .icon-arrow-up:before {
   content: "\e61a";
 }
 .icon-arrow-down:before {
   content: "\e61b";
 }
 .icon-arrow-right:before {
   content: "\e61c";
 }
 .icon-chevron-down:before {
   content: "\e61d";
 }
 .icon-back:before {
   content: "\e61e";
 }
 .icon-download:before {
   content: "\e61f";
 }
 .icon-upload:before {
   content: "\e620";
 }
 .icon-proceed:before {
   content: "\e621";
 }
 .icon-info:before {
   content: "\e622";
 }
 .icon-question:before {
   content: "\e623";
 }
 .icon-alert:before {
   content: "\e624";
 }
 .icon-edit:before {
   content: "\e625";
 }
 .icon-paintbrush:before {
   content: "\e626";
 }
 .icon-close:before {
   content: "\e627";
 }
 .icon-trash:before {
   content: "\e628";
 }
 .icon-cross:before {
   content: "\e629";
 }
 .icon-delete:before {
   content: "\e62a";
 }
 .icon-power:before {
   content: "\e62b";
 }
 .icon-add:before {
   content: "\e62c";
 }
 .icon-plus:before {
   content: "\e62d";
 }
 .icon-document:before {
   content: "\e62e";
 }
 .icon-graph-line:before {
   content: "\e62f";
 }
 .icon-doc-chart:before {
   content: "\e630";
 }
 .icon-doc-list:before {
   content: "\e631";
 }
 .icon-category:before {
   content: "\e632";
 }
 .icon-copy:before {
   content: "\e633";
 }
 .icon-book:before {
   content: "\e634";
 }
 .icon-certificate:before {
   content: "\e636";
 }
 .icon-print:before {
   content: "\e637";
 }
 .icon-list-unordered:before {
   content: "\e638";
 }
 .icon-graph-bar:before {
   content: "\e639";
 }
 .icon-menu:before {
   content: "\e63a";
 }
 .icon-filter:before {
   content: "\e63b";
 }
 .icon-ellipsis:before {
   content: "\e63c";
 }
 .icon-cog:before {
   content: "\e63d";
 }
 .icon-wrench:before {
   content: "\e63e";
 }
 .icon-nut:before {
   content: "\e63f";
 }
 .icon-camera:before {
   content: "\e640";
 }
 .icon-eye:before {
   content: "\e641";
 }
 .icon-photo:before {
   content: "\e642";
 }
 .icon-video:before {
   content: "\e643";
 }
 .icon-speaker:before {
   content: "\e644";
 }
 .icon-phone:before {
   content: "\e645";
 }
 .icon-flag:before {
   content: "\e646";
 }
 .icon-pin:before {
   content: "\e647";
 }
 .icon-compass:before {
   content: "\e648";
 }
 .icon-globe:before {
   content: "\e649";
 }
 .icon-location:before {
   content: "\e64a";
 }
 .icon-search:before {
   content: "\e64b";
 }
 .icon-timer:before {
   content: "\e64c";
 }
 .icon-time:before {
   content: "\e64d";
 }
 .icon-dashboard:before {
   content: "\e64e";
 }
 .icon-hourglass:before {
   content: "\e64f";
 }
 .icon-play:before {
   content: "\e650";
 }
 .icon-stop:before {
   content: "\e651";
 }
 .icon-email:before {
   content: "\e652";
 }
 .icon-comment:before {
   content: "\e653";
 }
 .icon-link:before {
   content: "\e654";
 }
 .icon-paperclip:before {
   content: "\e655";
 }
 .icon-box:before {
   content: "\e656";
 }
 .icon-structure:before {
   content: "\e657";
 }
 .icon-commit:before {
   content: "\e658";
 }
 .icon-cpu:before {
   content: "\e659";
 }
 .icon-memory:before {
   content: "\e65a";
 }
 .icon-outbox:before {
   content: "\e65b";
 }
 .icon-share:before {
   content: "\e65c";
 }
 .icon-button:before {
   content: "\e65d";
 }
 .icon-check:before {
   content: "\e65e";
 }
 .icon-form:before {
   content: "\e65f";
 }
 .icon-admin:before {
   content: "\e660";
 }
 .icon-paragraph:before {
   content: "\e661";
 }
 .icon-bell:before {
   content: "\e662";
 }
 .icon-rss:before {
   content: "\e663";
 }
 .icon-basket:before {
   content: "\e664";
 }
 .icon-credit:before {
   content: "\e665";
 }
 .icon-support:before {
   content: "\e666";
 }
 .icon-shield:before {
   content: "\e667";
 }
 .icon-beaker:before {
   content: "\e668";
 }
 .icon-google:before {
   content: "\e669";
 }
 .icon-gdrive:before {
   content: "\e66a";
 }
 .icon-youtube:before {
   content: "\e66b";
 }
 .icon-facebook:before {
   content: "\e66c";
 }
 .icon-thumbs-up:before {
   content: "\e66d";
 }
 .icon-twitter:before {
   content: "\e66e";
 }
 .icon-github:before {
   content: "\e66f";
 }
 .icon-meteor:before {
   content: "\e670";
 }
 a {
   transition: all 200ms ease-in;
   color: #5db9ff;
   cursor: pointer;
   text-decoration: none;
 }
 a:hover {
   color: #239da8;
 }
 a:active {
   color: #555;
 }
 a:focus {
   outline: none;
 }
 .list-heading {
   letter-spacing: 0.3em;
   text-indent: 0.3em;
   text-transform: uppercase;
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 800;
   font-size: 11px;
   padding-left: 15px;
   line-height: 40px;
   background: #f8f8f8;
   color: #aaa;
 }
 .list-heading .icon-sync {
   opacity: 1;
   animation: spin 2s infinite linear;
   display: inline-block;
   margin-right: 4px;
 }
 .list-item {
   font-size: 14px;
   line-height: 20px;
   display: flex;
   flex-wrap: wrap;
   height: 3rem;
   width: 100%;
   background: white;
   transition: all ease-out 150ms;
 }
 .list-item .title {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   flex: 1 1;
 }
 .list-item input[type="text"] {
   background: transparent;
   width: 100%;
 }
 .list-item input[type="text"]:focus {
   cursor: text;
 }
 .list-item .actions {
   transition: all 200ms ease-in;
   padding-right: 20px;
 }
 .list-item .actions a {
   display: inline-block;
   vertical-align: top;
   text-align: center;
   color: #eee;
 }
 .list-item .actions a:hover {
   color: #2cc5d2;
 }
 .list-item .actions a:active {
   color: #555;
 }
 .list-item .actions [class^="icon-"] {
   font-size: 16px;
   line-height: 24px;
   line-height: 3rem;
   text-align: center;
 }
 .list-item.TASK_PINNED .icon-star {
   color: #2cc5d2;
 }
 .list-item.TASK_ARCHIVED input[type="text"] {
   color: #aaa;
 }
 .list-item:hover {
   background-image: linear-gradient(to bottom, #e5f9f7 0%, #f0fffd 100%);
 }
 .list-item:hover .checkbox {
   cursor: pointer;
 }
 .list-item + .list-item {
   border-top: 1px solid #f0f9fb;
 }
 .list-item.checked input[type="text"] {
   color: #ccc;
   text-decoration: line-through;
 }
 .list-item.checked .delete-item {
   display: inline-block;
 }
 .loading-item {
   height: 3rem;
   width: 100%;
   background: white;
   display: flex;
   align-items: center;
   line-height: 1rem;
   padding-left: 16px;
 }
 .loading-item .glow-checkbox,
 .loading-item .glow-text span {
   animation: glow 1.5s ease-in-out infinite;
   background: #eee;
   color: transparent;
   cursor: progress;
   display: inline-block;
 }
 .loading-item .glow-checkbox {
   margin-right: 16px;
   width: 12px;
   height: 12px;
 }
 .loading-item + .loading-item {
   border-top: 1px solid #f0f9fb;
 }
 .list-items {
   position: relative;
   background: white;
   min-height: 288px;
 }
 .list-items .select-placeholder {
   border: none;
   width: 48px;
 }
 .wrapper-message {
   position: absolute;
   top: 45%;
   right: 0;
   bottom: auto;
   left: 0;
   width: auto;
   height: auto;
   transform: translate3d(0, -50%, 0);
   text-align: center;
 }
 .wrapper-message [class^="icon-"],
 .wrapper-message [class*=" icon-"] {
   font-size: 48px;
   line-height: 56px;
   color: #2cc5d2;
   display: block;
 }
 .wrapper-message .title-message {
   font-size: 16px;
   line-height: 24px;
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 800;
   color: #555;
 }
 .wrapper-message .subtitle-message {
   font-size: 14px;
   line-height: 20px;
   color: #666;
 }
 .page.lists-show {
   min-height: 100vh;
   background: white;
 }
 .page.lists-show nav {
   background: #d3edf4;
   padding: 1.5rem 1.25rem;
   text-align: center;
 }
 @media screen and (min-width: 40em) {
   .page.lists-show nav {
     text-align: left;
   }
 }
 .page.lists-show nav .title-page {
   font-size: 20px;
   line-height: 24px;
   line-height: 2rem;
   cursor: pointer;
   white-space: nowrap;
 }
 .page.lists-show nav .title-page .title-wrapper {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-family: 'Nunito Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 800;
   color: #1c3f53;
   display: inline-block;
   vertical-align: top;
   max-width: 100%;
 }
.App {
  line-height: 16px;
  padding: 20px;
  text-align: left;
}

.quote {
  padding: 20px 0;
}

.calendly-inline-widget {
  min-height:1060px;
  min-width:320px;
}

.mission-statement {
  font-size: 28px;
  line-height: normal;
}

.contact-us__button {
  margin: 20px 0;
  border-radius: 2px;
  /* color: #011627; */
  color: #011627;
  font-weight: 300;
  padding: 12px;
  /*background-color: #03c04a;*/
  background-color: #03c04a;
}

.modal__close-button {
  font-size: 24px;
  color: #03c04a;
  background-color: transparent;
  font-weight: 100;
  border: 1px solid #03c04a;
  padding: 5px 10px;
}

.modal {
  /*...*/
  visibility: hidden;
  transition: visibility 0s 0.6s;
  margin-left: 20px;
}

.modal.modal--is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.modal__header {
  /*...*/
  position: fixed;
  top: 0;
  width: 90%;
  height: 50px;
  transition: transform 0.3s 0s;
  transform: translateY(-50px);
  z-index: 100;
  padding: 10px 20px;
}

.modal--from-right .modal__header {
  right: 0;
}

.modal--from-left .modal__header {
  left: 0;
}

.modal--is-visible .modal__header {
  transition: transform 0.3s 0.3s;
  transform: translateY(0px);
}

.modal__container {
  /*
  border-left: 1px solid #1c3f53;
  background-color: #011627;
   */
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  background-color: #011627;
  padding: 20px 20px;
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  transition: transform 0.3s 0.3s;
}

@media only screen and (max-width: 767px) {
  .modal__container {
    padding: 70px 20px;
  }
}

.modal--from-right .modal__container {
  right: 0;
  transform: translate3d(100%, 0, 0);
}

.modal--from-left .modal__container {
  left: 0;
  transform: translate3d(-100%, 0, 0);
}

.modal--is-visible .modal__container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

p {
  margin-bottom: 10px;
}

header {
  padding-bottom: 20px;
}

footer {
  padding-top: 20px;
}

.birdseed {
  font-size: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo a {
    color: #fff;
    font-weight: 900;
    opacity: 0.9;
    size: 24px;
}

.logo a:hover {
    color: #fff;
    opacity: 1;
}

.logo a span {
    font-weight: 100;
}
